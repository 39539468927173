<template>
  <div class="row row-cols-2 row-cols-md-3 row-cols-xxl-6 g-4">
    <template v-for="(item, index) in swinglist">
      <div :key="index">
        <div class="inbox">
          <router-link
            :to="{ name: 'NetizenLessonView', params: { id: item.swingno } }"
          >
            <div class="video-box">
              <img src="/img/ico_play_circle_white_32px.png" class="mov_icon">
              <img
                src="/img/video_img.png"
                class="d-block img-fluid"
                v-if="browser === 'safari'"
              />
              <video
                :src="item.path + '/' + item.swingmovie"
                v-else
              ></video>
            </div>
            <div class="info-box">
              <h5>
                {{ item.title || "Untitled" }}
              </h5>
              <p class="my-2 my-lg-3">{{ item.nickname }}</p>
              <!-- span class="d-inline-block">{{ item.id }}</span -->
              <p class="fs-14">
                <i class="material-icons-outlined">textsms</i
                >{{ item.replycnt | comma }}
              </p>
            </div>
          </router-link>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import "@/filter/common";
export default {
  name: "Lesson",
  data() {
    return {
      browser: "",
    };
  },
  props: ["swinglist"],
  methods: {
    check_browser: function () {
      let agent = navigator.userAgent.toLowerCase();
      let isiOSSafari = navigator.userAgent.match(/like Mac OS X/i)
        ? true
        : false;
      if (isiOSSafari) {
        if (agent.indexOf("safari") !== -1) {
          this.browser = "safari";
        }
      }
    },
  },
  mounted() {
    this.check_browser();
  },
};
</script>