var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tourn-card row row-cols-1 row-cols-md-2 row-cols-xxl-4 g-4 gy-5"},[_vm._l((_vm.tournamentlist),function(item,index){return [_c('div',{key:index,staticClass:"col"},[_c('router-link',{attrs:{"to":{ name: 'Condition', params: { id: item.tournamentno } }}},[_c('div',{staticClass:"card h-100"},[_c('div',{staticClass:"card-img-wrapper"},[_c('img',{attrs:{"src":_vm._f("get_img")(item.tourbinary &&
                item.tourbinary[0] &&
                item.tourbinary[0].filepath,
                    'https://krstorage.s3.ap-northeast-2.amazonaws.com/files/tournament/06edda88fe1bb993db760ee25b8ad9fa.jpg'
                  )}})]),_c('div',{staticClass:"card-body"},[_c('span',{staticClass:"tourn-type badge rounded-pill"},[_vm._v(_vm._s(_vm.tournament_type(item.tourtype_fk))+" / "+_vm._s(_vm._f("tour_rank_type")(item.tour_rank_type))+" ")]),_c('h5',{staticClass:"card-title"},[_vm._v(_vm._s(item.title))])]),_c('div',{staticClass:"card-footer"},[(
                item.timezone_startdate &&
                item.timezone_startdate.timezone_private
              )?[_c('p',{staticClass:"text-date text-truncate mb-1"},[_c('dfn',{staticClass:"time-zone-mark badge rounded-pill bg-orange",attrs:{"title":"Connect time"}},[_vm._v("CT")]),_vm._v(" "+_vm._s(_vm._f("dateformat")(item.timezone_startdate.timezone_private))+" ~ "+_vm._s(_vm._f("dateformat")(item.timezone_enddate.timezone_private))+" ")])]:_vm._e(),_c('p',{staticClass:"text-date text-truncate"},[_c('dfn',{staticClass:"time-zone-mark badge rounded-pill bg-green",attrs:{"title":"Standard time"}},[_vm._v("ST")]),_vm._v(" "+_vm._s(_vm._f("dateformat")(item.timezone_startdate.timezone_common))+" ~ "+_vm._s(_vm._f("dateformat")(item.timezone_enddate.timezone_common))+" ")])],2)])])],1)]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }